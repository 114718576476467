import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _05cbd3f5 = () => interopDefault(import('../pages/car-tax-landing.vue' /* webpackChunkName: "pages/car-tax-landing" */))
const _4f0a4d90 = () => interopDefault(import('../pages/confidential-policy.vue' /* webpackChunkName: "pages/confidential-policy" */))
const _fe27da8a = () => interopDefault(import('../pages/contestation/index.vue' /* webpackChunkName: "pages/contestation/index" */))
const _7a95a464 = () => interopDefault(import('../pages/elastic.vue' /* webpackChunkName: "pages/elastic" */))
const _1d16aac2 = () => interopDefault(import('../pages/email-landing.vue' /* webpackChunkName: "pages/email-landing" */))
const _96d63e7e = () => interopDefault(import('../pages/expert.vue' /* webpackChunkName: "pages/expert" */))
const _42928b00 = () => interopDefault(import('../pages/o-servise.vue' /* webpackChunkName: "pages/o-servise" */))
const _107eac7f = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _7de72748 = () => interopDefault(import('../pages/payment-fail.vue' /* webpackChunkName: "pages/payment-fail" */))
const _fb793c74 = () => interopDefault(import('../pages/payment-history.vue' /* webpackChunkName: "pages/payment-history" */))
const _76e67aa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _72c92716 = () => interopDefault(import('../pages/saved-cards.vue' /* webpackChunkName: "pages/saved-cards" */))
const _32dfe183 = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _470553ad = () => interopDefault(import('../pages/subscription-payment.vue' /* webpackChunkName: "pages/subscription-payment" */))
const _1366216c = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _448b4034 = () => interopDefault(import('../pages/vacation.vue' /* webpackChunkName: "pages/vacation" */))
const _48551e0f = () => interopDefault(import('../pages/contestation/search.vue' /* webpackChunkName: "pages/contestation/search" */))
const _2cab08b9 = () => interopDefault(import('../pages/contestation/search/consultation.vue' /* webpackChunkName: "pages/contestation/search/consultation" */))
const _12b09f4c = () => interopDefault(import('../pages/contestation/search/creditor.vue' /* webpackChunkName: "pages/contestation/search/creditor" */))
const _2992ebf4 = () => interopDefault(import('../pages/contestation/search/decision-rendered.vue' /* webpackChunkName: "pages/contestation/search/decision-rendered" */))
const _698ea0bb = () => interopDefault(import('../pages/contestation/search/download-application.vue' /* webpackChunkName: "pages/contestation/search/download-application" */))
const _b39c51c0 = () => interopDefault(import('../pages/contestation/search/download-application-list.vue' /* webpackChunkName: "pages/contestation/search/download-application-list" */))
const _66e0d2c8 = () => interopDefault(import('../pages/contestation/search/employment.vue' /* webpackChunkName: "pages/contestation/search/employment" */))
const _0f617766 = () => interopDefault(import('../pages/contestation/search/empty.vue' /* webpackChunkName: "pages/contestation/search/empty" */))
const _6601859c = () => interopDefault(import('../pages/contestation/search/forms-application.vue' /* webpackChunkName: "pages/contestation/search/forms-application" */))
const _7370a1c2 = () => interopDefault(import('../pages/contestation/search/passport.vue' /* webpackChunkName: "pages/contestation/search/passport" */))
const _4d89b14b = () => interopDefault(import('../pages/contestation/search/performance-list.vue' /* webpackChunkName: "pages/contestation/search/performance-list" */))
const _40310921 = () => interopDefault(import('../pages/contestation/search/receiving-order.vue' /* webpackChunkName: "pages/contestation/search/receiving-order" */))
const _6a42830c = () => interopDefault(import('../pages/contestation/search/receiving-order-list.vue' /* webpackChunkName: "pages/contestation/search/receiving-order-list" */))
const _cd5b9656 = () => interopDefault(import('../pages/fssp-contacts/index/index.vue' /* webpackChunkName: "pages/fssp-contacts/index/index" */))
const _aa3aeb3a = () => interopDefault(import('../pages/fssp-contacts/to-detail.vue' /* webpackChunkName: "pages/fssp-contacts/to-detail" */))
const _16dfd756 = () => interopDefault(import('../pages/fssp-contacts/view.vue' /* webpackChunkName: "pages/fssp-contacts/view" */))
const _374539db = () => interopDefault(import('../pages/search/fns.vue' /* webpackChunkName: "pages/search/fns" */))
const _853af72c = () => interopDefault(import('../pages/search/fssp.vue' /* webpackChunkName: "pages/search/fssp" */))
const _30ee5078 = () => interopDefault(import('../pages/static/category.vue' /* webpackChunkName: "pages/static/category" */))
const _02380689 = () => interopDefault(import('../pages/static/page.vue' /* webpackChunkName: "pages/static/page" */))
const _17b5baa1 = () => interopDefault(import('../pages/widgets/fssp.vue' /* webpackChunkName: "pages/widgets/fssp" */))
const _01debcea = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _fc33dfdc = () => interopDefault(import('../pages/static-old/show-category/_alias.vue' /* webpackChunkName: "pages/static-old/show-category/_alias" */))
const _3877b402 = () => interopDefault(import('~/pages/unsubscribe' /* webpackChunkName: "" */))
const _19ba9684 = () => interopDefault(import('~/pages/static/page' /* webpackChunkName: "" */))
const _4a61f494 = () => interopDefault(import('~/pages/email-landing' /* webpackChunkName: "" */))
const _57d8121e = () => interopDefault(import('../node_modules/@pp-frontend/payment-success-module/pages/payment-success-page' /* webpackChunkName: "" */))
const _550e5fad = () => interopDefault(import('~/pages/static/category' /* webpackChunkName: "" */))
const _ba027b72 = () => interopDefault(import('~/pages/static-old/show-category/_alias' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/car-tax-landing",
    component: _05cbd3f5,
    name: "car-tax-landing"
  }, {
    path: "/confidential-policy",
    component: _4f0a4d90,
    name: "confidential-policy"
  }, {
    path: "/contestation",
    component: _fe27da8a,
    name: "contestation"
  }, {
    path: "/elastic",
    component: _7a95a464,
    name: "elastic"
  }, {
    path: "/email-landing",
    component: _1d16aac2,
    name: "email-landing"
  }, {
    path: "/expert",
    component: _96d63e7e,
    name: "expert"
  }, {
    path: "/o-servise",
    component: _42928b00,
    name: "o-servise"
  }, {
    path: "/payment",
    component: _107eac7f,
    name: "payment"
  }, {
    path: "/payment-fail",
    component: _7de72748,
    name: "payment-fail"
  }, {
    path: "/payment-history",
    component: _fb793c74,
    name: "payment-history"
  }, {
    path: "/profile",
    component: _76e67aa2,
    name: "profile"
  }, {
    path: "/saved-cards",
    component: _72c92716,
    name: "saved-cards"
  }, {
    path: "/subscribe",
    component: _32dfe183,
    name: "subscribe"
  }, {
    path: "/subscription-payment",
    component: _470553ad,
    name: "subscription-payment"
  }, {
    path: "/unsubscribe",
    component: _1366216c,
    name: "unsubscribe"
  }, {
    path: "/vacation",
    component: _448b4034,
    name: "vacation"
  }, {
    path: "/contestation/search",
    component: _48551e0f,
    name: "contestation-search",
    children: [{
      path: "consultation",
      component: _2cab08b9,
      name: "contestation-search-consultation"
    }, {
      path: "creditor",
      component: _12b09f4c,
      name: "contestation-search-creditor"
    }, {
      path: "decision-rendered",
      component: _2992ebf4,
      name: "contestation-search-decision-rendered"
    }, {
      path: "download-application",
      component: _698ea0bb,
      name: "contestation-search-download-application"
    }, {
      path: "download-application-list",
      component: _b39c51c0,
      name: "contestation-search-download-application-list"
    }, {
      path: "employment",
      component: _66e0d2c8,
      name: "contestation-search-employment"
    }, {
      path: "empty",
      component: _0f617766,
      name: "contestation-search-empty"
    }, {
      path: "forms-application",
      component: _6601859c,
      name: "contestation-search-forms-application"
    }, {
      path: "passport",
      component: _7370a1c2,
      name: "contestation-search-passport"
    }, {
      path: "performance-list",
      component: _4d89b14b,
      name: "contestation-search-performance-list"
    }, {
      path: "receiving-order",
      component: _40310921,
      name: "contestation-search-receiving-order"
    }, {
      path: "receiving-order-list",
      component: _6a42830c,
      name: "contestation-search-receiving-order-list"
    }]
  }, {
    path: "/fssp-contacts/index",
    component: _cd5b9656,
    name: "fssp-contacts-index"
  }, {
    path: "/fssp-contacts/to-detail",
    component: _aa3aeb3a,
    name: "fssp-contacts-to-detail"
  }, {
    path: "/fssp-contacts/view",
    component: _16dfd756,
    name: "fssp-contacts-view"
  }, {
    path: "/search/fns",
    component: _374539db,
    name: "search-fns"
  }, {
    path: "/search/fssp",
    component: _853af72c,
    name: "search-fssp"
  }, {
    path: "/static/category",
    component: _30ee5078,
    name: "static-category"
  }, {
    path: "/static/page",
    component: _02380689,
    name: "static-page"
  }, {
    path: "/widgets/fssp",
    component: _17b5baa1,
    name: "widgets-fssp"
  }, {
    path: "/",
    component: _01debcea,
    name: "index"
  }, {
    path: "/static-old/show-category/:alias?",
    component: _fc33dfdc,
    name: "static-old-show-category-alias"
  }, {
    path: "/email/unsubscribe",
    components: {
      default: _3877b402
    }
  }, {
    path: "/:type(pages|news)/:alias",
    components: {
      default: _19ba9684
    }
  }, {
    path: "/:type(fssp|fns|gibdd)/landing",
    components: {
      default: _4a61f494
    },
    name: "email"
  }, {
    path: "/email/:type(fssp|fns|gibdd)-landing",
    components: {
      default: _4a61f494
    },
    name: "email-land"
  }, {
    path: "/payment-success",
    component: _57d8121e,
    props: (route) => ({ project: 'oplata-fssp' }),
    name: "payment-success"
  }, {
    path: "/:type(pages|news)",
    components: {
      default: _550e5fad
    }
  }, {
    path: "/pages/show-category/:alias",
    components: {
      default: _ba027b72
    }
  }],

  parseQuery: (query) => require('qs').parse(query),
  stringifyQuery: (query) => {
    const result = require('qs').stringify(query);
    return result ? '?' + result : '';
  },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
